/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {Text} from 'wix-ui-tpa/cssVars';
import s from './FiltersHeader.scss';
import {X} from '../../../../icons/dist';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';
import classNames from 'classnames';
import {ProductsCounter} from '../../../../category/components/ProductsCounter/ProductsCounter';
import {ConditionalRender} from '../../../../category/components/ConditionalRender/ConditionalRender';
import {Experiments} from '../../../../constants';

export interface IFiltersHeaderProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  combineFiltersAndSort?: boolean;
  modalHeaderId?: string;
}

export class FiltersHeaderComp extends React.Component<IFiltersHeaderProps> {
  public render() {
    const {shouldShowMobile, usingNewGalleryStyles, applyFilteredProductsOnMobile} = this.props.globals;
    const {combineFiltersAndSort} = this.props;
    const htmlTag = this.props.globals.htmlTags.categoriesFiltersHtmlTag;
    const renderFilterHeaderText = () => {
      return (
        <>
          <span>
            {combineFiltersAndSort ? this.props.t('mobileFiltersAndSortingText') : this.props.t('filtersTitleText')}
          </span>
          {shouldShowMobile && usingNewGalleryStyles && (
            <>
              <span>&nbsp;</span>
              <ProductsCounter mobileInlineMode={true} />
            </>
          )}
        </>
      );
    };

    return (
      <span
        className={classNames(s.header, {
          [s.usingNewGalleryStyles]: usingNewGalleryStyles,
        })}>
        <ConditionalRender by={'gallery_showFiltersTitle'}>
          {this.props.globals.useExperiments.enabled(Experiments.GalleryA11yFixes) ? (
            <Text
              tagName={htmlTag}
              data-hook="filters-title"
              className={classNames(s.title, {[s.usingNewGalleryStyles]: usingNewGalleryStyles})}
              id={this.props.modalHeaderId}>
              {renderFilterHeaderText()}
            </Text>
          ) : (
            <h2
              data-hook="filters-title"
              className={classNames(s.title, {[s.usingNewGalleryStyles]: usingNewGalleryStyles})}
              id={this.props.modalHeaderId}>
              {renderFilterHeaderText()}
            </h2>
          )}
        </ConditionalRender>
        {shouldShowMobile && (
          <button
            type="button"
            data-hook="cancel"
            aria-label={this.props.t('filtersAriaLabel')}
            className={s.cancel}
            onClick={() => applyFilteredProductsOnMobile()}>
            <X />
          </button>
        )}
      </span>
    );
  }
}

export const FiltersHeader = withGlobals(withTranslations()(FiltersHeaderComp));
